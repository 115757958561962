import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const ContactContent = () => {
  const data = useStaticQuery(graphql`
    query {
      connectmap: file(relativePath: { eq: "contact/location.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div className="connect-location">
      <Img
        fluid={data.connectmap.childImageSharp.fluid}
        alt=""
        className="connect-location-img"
      />
      <div className="connect-location-info-box">
        <div className="connect-location-info">
          <div className="title">BBA</div>
          <div className="text">
            Let us recommend solutions or any other information you need to know
            about BBA Find us at bbaservice@network.com{" "}
          </div>
          <div className="line"></div>
          <svg className="icon">
            <use href="#iconStationConstruction" />
          </svg>
          <div className="email-info">
            Station Construction Contact:{" "}
            <strong>bbaservice@network.com </strong>
          </div>
          <br />
          <svg className="icon">
            <use href="#iconFeedback" />
          </svg>
          <div className="email-info">
            Feedback: <strong>bbaservice@network.com </strong>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactContent
